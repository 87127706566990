

import img1 from '../images/icon/manager.png'
import img2 from '../images/icon/Lock.png'
import img3 from '../images/icon/smartphone.png'
const dataServices2 = [
    {
        id: 1,
        icon: img1,
        title: 'Managing your AI',
        text: 'Let AI give you a clear insight into your decisions',
        active: 'active',
        bg:''
    },
    {
        id: 2,
        icon: img2,
        title: 'Exclusive algorithm, your AI expert',
        text: 'Provide your AI strategy to give you financial clarity and planning.',
        active: '',
        bg:'green'
    },
    {
        id: 3,
        icon: img3,
        title: 'Mobile apps',
        text: 'Stay On Top Of The Markets With The Cryptolly App For Android Or IOS.',
        active: '',
        bg:'blue'
    },

]

export default dataServices2;
